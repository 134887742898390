import { DEFAULT_TIMEZONE, getUserUnload, setCurrentTimezone } from '@/core'
import {
  FETCH_TERMINALS,
  GET_ARRIVAL_PREFIX,
  GET_HAS_BLACKLIST,
  GET_IS_ALLOWED_MANUAL_TS,
  GET_IS_INCOMPLETE_PARKING,
  GET_IS_USE_COMPLEX_PARKING_STATUSES,
  GET_LATE_INTERVAL,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_CURRENT_ID_SLUG,
  GET_TERMINAL_CURRENT_REQUIRE_INN,
  GET_TERMINAL_LIST,
  GET_TIMEZONE_OFFSET,
  SET_TERMINAL,
  SET_TERMINALS,
  SET_TERMINAL_SLUG,
  SET_TIMEZONE_OFFSET,
} from '@/store/actions'
import API from '@/api'

const defaultUnload = getUserUnload()

export default {
  state: {
    terminals: [],
    terminalCurrentId: Number(defaultUnload?.id) || null,
    terminalCurrentIdSlug: defaultUnload?.slug || '',
    terminalCurrentRequireInn: defaultUnload?.require_inn || false,
    isIncompleteParking: Boolean(defaultUnload?.use_incomplete_parking),
    isAllowedManualTs: Boolean(defaultUnload?.allow_creating_manual_ts),
    isUseComplexParkingStatuses: Boolean(
      defaultUnload?.use_complex_parking_statuses,
    ),
    acceptableLateInterval: defaultUnload?.hours_allowed_late || 0,
    arrivalPrefix: defaultUnload?.minutes_allowed_early || 0,
    hasBlacklist: Boolean(defaultUnload?.blacklist_id) || null,
    timezoneOffset: defaultUnload?.timezone_utc_offset || 3,
  },
  getters: {
    [GET_TERMINAL_LIST]: state => state.terminals,
    [GET_TERMINAL_CURRENT_ID]: state => state.terminalCurrentId,
    [GET_TERMINAL_CURRENT_ID_SLUG]: state => state.terminalCurrentIdSlug,
    [GET_TERMINAL_CURRENT_REQUIRE_INN]: state =>
      state.terminalCurrentRequireInn,
    [GET_IS_INCOMPLETE_PARKING]: state => state.isIncompleteParking,
    [GET_IS_ALLOWED_MANUAL_TS]: state => state.isAllowedManualTs,
    [GET_IS_USE_COMPLEX_PARKING_STATUSES]: state =>
      state.isUseComplexParkingStatuses,
    [GET_LATE_INTERVAL]: state => state.acceptableLateInterval,
    [GET_ARRIVAL_PREFIX]: state => state.arrivalPrefix,
    [GET_HAS_BLACKLIST]: state => state.hasBlacklist,
    [GET_TIMEZONE_OFFSET]: state => state.timezoneOffset,
  },
  actions: {
    [FETCH_TERMINALS]({ commit, getters }) {
      API.terminal
        .get()
        .then(({ data }) => {
          commit(SET_TERMINALS, data)

          if (!getters[GET_TERMINAL_CURRENT_ID] && data) {
            commit(SET_TERMINAL, data[0])
          } else if (getters[GET_TERMINAL_CURRENT_ID]) {
            // это ветка для страницы Настройки. В случае изменения параметров терминала -
            // сразу обновляем его в списке терминалов (ну и геттеры изменятся, само собой)
            let currentTerminal = data.find(
              item => item.id === getters[GET_TERMINAL_CURRENT_ID],
            )

            commit(SET_TERMINAL, currentTerminal)
          }
          if (!getters[GET_TERMINAL_CURRENT_ID_SLUG] && data) {
            let currentSlug = getters[GET_TERMINAL_LIST].filter(
              item => item.id === getters[GET_TERMINAL_CURRENT_ID],
            )[0]?.slug

            currentSlug ? commit(SET_TERMINAL_SLUG, currentSlug) : null
          }
        })
        .catch(error => console.log('error', error))
    },
  },
  mutations: {
    [SET_TERMINALS](state, payload) {
      state.terminals = payload
    },
    [SET_TERMINAL](state, payload) {
      state.terminalCurrentId = payload?.id || null
      state.terminalCurrentIdSlug = payload?.slug || null
      state.terminalCurrentRequireInn = payload?.require_inn || false
      state.isIncompleteParking = Boolean(payload?.use_incomplete_parking)
      state.isAllowedManualTs = Boolean(payload?.allow_creating_manual_ts)
      state.isUseComplexParkingStatuses = Boolean(
        payload?.use_complex_parking_statuses,
      )
      state.acceptableLateInterval = payload?.hours_allowed_late || 0
      state.arrivalPrefix = payload?.minutes_allowed_early || 0
      state.hasBlacklist = payload?.blacklist_id || false
      state.timezoneOffset = payload?.timezone_utc_offset || DEFAULT_TIMEZONE
      setCurrentTimezone(state.timezoneOffset)
      localStorage.setItem('terminal-timezone', state.timezoneOffset)
    },
    [SET_TERMINAL_SLUG](state, payload) {
      state.terminalCurrentIdSlug = payload?.slug || null
    },
    [SET_TIMEZONE_OFFSET](state, payload) {
      state.timezoneOffset = payload
      localStorage.setItem('terminal-timezone', payload)
    },
  },
}
