export const DIALOG_ADD_QUOTA = 'dialog-add-quota'
export const HOME_DELETE_QUOTA = 'home-delete-quota'
export const DIALOG_IMPROVE_QUOTA = 'dialog-improve-quota'
export const DIALOG_PARKING_INFO = 'dialog-parking-info'
export const TIMESLOT_SHIFT_PARAMS = 'timeslot-shift-params'
export const TIMESLOT_SHIFT_ACCEPT = 'timeslot-shift-accept'
export const TIMESLOT_SHIFT_RESET = 'timeslot-shift-reset'

export const CONTROL_TABLE_CULTURE_ADD_CULTURE =
  'control-table-culture-add-culture'
export const CONTROL_TABLE_CULTURE_REMOVE_ROW =
  'control-table-culture-remove-row'

// SUPPLIER - это поставщик
// EXPORTER - это экспортёр
export const CONTROL_TABLE_SUPPLIER_REMOVE_ROW =
  'control-table-exporter-remove-row'
export const CONTROL_TABLE_EXPORTER_REMOVE_ROW =
  'control-table-exporter-remove-row'
export const CONTROL_TABLE_BLACKLIST_REMOVE_ROW =
  'control-table-blacklist-remove-row'
export const CONTROL_TABLE_SUPPLIER_EDIT_ROW = 'control-table-supplier-edit-row'
export const CONTROL_TABLE_EXPORTER_EDIT_ROW = 'control-table-exporter-edit-row'
export const CONTROL_TABLE_BLACKLIST_EDIT_ROW =
  'control-table-blacklist-edit-row'

export const MANAGER_ADD_TIMESLOT = 'manager-add-timeslot'
export const MANAGER_ADD_TIMESLOT_HAND = 'manager-add-timeslot-hand'
export const STEP_MANAGER_ADD_TIMESLOT_HAND = 'step-manager-add-timeslot-hand'
export const MANAGER_TIMESLOT_VIEWING = 'manager-timeslot-viewing'
export const MANAGER_TIMESLOT_WRONG = 'manager-timeslot-wrong'
export const MANAGER_EDIT_TIMESLOT = 'manager-edit-timeslot'
export const STEP_MANAGER_EDIT_TIMESLOT = 'step-manager-edit-timeslot'
export const MANAGER_DELETE_TIMESLOT = 'manager-delete-timeslot'
export const ADMIN_DELETE_TIMESLOT = 'admin-delete-timeslot'
export const MANAGER_FORCE_ARRIVAL = 'manager-force-arrival'
export const MANAGER_CHANGE_TABLE_COLUMN = 'manager-change-table-column'
export const PARKMAN_CHANGE_TABLE_COLUMN = 'parkman-change-table-column'

export const PROFILE_NEW_USER = 'profile-new-user'

export const DIALOG_ADD_SAMPLE = 'dialog-add-sample'
export const QUOTA_DELETE_SAMPLE = 'quota-delete-sample'
export const QUOTA_APPLY_SAMPLES = 'quota-apply-samples'
export const SUPERVISOR_PARKING_INFO = 'supervisor-parking-info'
export const SUPERVISOR_DELETE_QUOTA = 'supervisor-delete-quota'
export const SUPERVISOR_DELETE_SAMPLE = 'supervisor-delete-sample'
export const SUPERVISOR_EDIT_QUOTA = 'supervisor-edit-quota'
export const SUPERVISOR_EDIT_SAMPLE = 'supervisor-edit-sample'
export const SUPERVISOR_APPLY_SAMPLES = 'supervisor-apply-samples'
