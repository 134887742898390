export const IS_PROD = process.env.NODE_ENV === 'production'

export const ENV = {
  LS_KEY: process.env.VUE_APP_LS_KEY,
  BASE_API_URL: IS_PROD
    ? process.env.VUE_APP_API_URL_PROD
    : process.env.VUE_APP_API_URL,
  API_URL: process.env.VUE_APP_API_URL,
  NODE_ENV: process.env.NODE_ENV,
  VERSION: process.env.VUE_APP_VERSION,
  BASE_URL: IS_PROD
    ? process.env.VUE_APP_URL_PROD
    : process.env.VUE_APP_URL_TEST,
}
