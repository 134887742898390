import Blacklist from '@/views/control/store/modules/blacklist'
import Cultures from '@/views/control/store/modules/cultures'
import Exporters from '@/views/control/store/modules/exporters'

export default {
  modules: {
    Cultures,
    Exporters,
    Blacklist,
  },
}
