import {
  DATE_FORMAT,
  DATE_FORMAT_FULL,
  DATE_FORMAT_HOURS,
  DATE_FORMAT_RU,
  GET_DAY,
  GET_TIME,
  MILLISECOND_IN_DAY,
} from '@/constants/date'
import { GET_TIMEZONE_OFFSET } from '@/store/actions'
import moment from 'moment-timezone'
import store from '@/store'

moment.locale('ru')
export default moment

export const textTimezones = {
  2: 'Europe/Kaliningrad',
  3: 'Europe/Moscow',
  4: 'Europe/Astrakhan',
  5: 'Asia/Yekaterinburg',
  6: 'Asia/Omsk',
  7: 'Asia/Novosibirsk',
  8: 'Asia/Irkutsk',
  9: 'Asia/Chita',
  10: 'Asia/Vladivostok',
  11: 'Asia/Magadan',
  12: 'Asia/Anadyr',
}

// часовой пояс по умолчанию = мск
export const DEFAULT_TIMEZONE = 3

export const setCurrentTimezone = zone =>
  moment.tz.setDefault(textTimezones[zone])

export const day = param => moment(param)

export const getTime = (date, format, defaultValue = '') => {
  if (date) {
    return moment(date).format(format)
  }

  return defaultValue
}

export const getTimeTo = (date, format) => {
  let a = moment(date).format(format)

  return a === '00:00' ? '24:00' : a
}

export const now = () => moment()

export const timerDiff = diffDate => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
  let defaultDate = moment(diffDate, DATE_FORMAT_FULL).format('x')

  moment.tz.setDefault()

  return moment(defaultDate, 'x').diff(now(), 'seconds')
}
export const diffTime = (from, to, unit = 'milliseconds') =>
  moment(from).diff(moment(to), unit)

export const getDateToUnix = date => Number(moment(date).format('x'))

export const nowUnix = () => getDateToUnix(moment())

export const pickerOptions = {
  disabledDate(time) {
    moment.tz.setDefault()
    let terminalDate = moment(time).format(DATE_FORMAT)

    moment.tz.setDefault(
      textTimezones[localStorage.getItem('terminal-timezone')],
    )

    return getDateToUnix(terminalDate) < nowUnix() - MILLISECOND_IN_DAY
  },
}

export const isPickerDateToday = date => {
  moment.tz.setDefault()
  let pickerDate = moment(date).format(DATE_FORMAT)

  moment.tz.setDefault(textTimezones[localStorage.getItem('terminal-timezone')])

  return pickerDate === nowTz().format(DATE_FORMAT)
}

export const getHours = date => moment(date).get('hour')
export const getMinutes = date => moment(date).get('minute')

export const timeConvert = n => {
  let hours = n / 60
  let rhours = Math.floor(hours)
  let minutes = (hours - rhours) * 60
  let rminutes = Math.round(minutes)

  return rhours + ':' + rminutes
}

export const dateForm = (seconds = 0) => {
  const d = Number(seconds)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)
  const hDisplay =
    h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : '00'
  const mDisplay =
    m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : '00'
  const sDisplay =
    s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : '00'

  return `${hDisplay}:${mDisplay}:${sDisplay}`
}

// ============ добавленные при переходе к часовым поясам =================================

export const nowTz = () => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return moment()
}

export const getTzDate = date => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return moment(date).format(DATE_FORMAT)
}

export const getTzTime = (date, format) => {
  moment.tz.setDefault(textTimezones[localStorage.getItem('terminal-timezone')])

  return moment(date).format(format)
}

export const getDateTzFromUnix = (date, format) => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return moment(date, 'X').format(format)
}

export const getFinishTzDate = date => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])
  let a = moment(date).format(GET_TIME)

  return a === '00:00'
    ? moment(date).subtract(1, 'days').format(DATE_FORMAT)
    : moment(date).format(DATE_FORMAT)
}

export const getTimeslotTzTime = (date, hours, minutes) => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])
  let updateDate = moment(moment.utc(date), DATE_FORMAT)
    .tz(moment.tz.guess())
    .format(DATE_FORMAT)

  return moment(moment(updateDate).format(DATE_FORMAT), DATE_FORMAT)
    .startOf('day')
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .format('X')
}

export const confirmedTimeslotDate = date => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
  let dateTz = moment(date).format('X')

  moment.tz.setDefault(textTimezones[localStorage.getItem('terminal-timezone')])

  return moment(dateTz, 'X').format(GET_DAY)
}

export const confirmedTimeslotTime = date => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
  let dateTz = moment(date).format('X')

  moment.tz.setDefault(textTimezones[localStorage.getItem('terminal-timezone')])

  return moment(dateTz, 'X').format(GET_TIME)
}

export const getFormattedTzDate = (date, format) => {
  if (!date) {
    return null
  }
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
  let dateTz = moment(date).format('X')

  moment.tz.setDefault(textTimezones[localStorage.getItem('terminal-timezone')])

  return moment(dateTz, 'X').format(format)
}

export const getFormattedTzNow = (format = DATE_FORMAT) => {
  moment.tz.setDefault(textTimezones[localStorage.getItem('terminal-timezone')])

  return moment().format(format)
}

export const diffTimeFromNowTz = diffDate => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
  let dateTz = moment(diffDate).format('X')

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return nowTz().diff(moment(dateTz, 'X'))
}

export const getUnixFromTzDate = (date, time = '00:00') => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return moment(`${date} ${time}`, DATE_FORMAT_HOURS).format('X')
}

export const getLocalUnixFromTzBriefDate = date => {
  moment.tz.setDefault()

  return moment(date, DATE_FORMAT).format('x')
}

export const getLocalBriefDate = date => {
  moment.tz.setDefault()
  let result = moment(date).format(DATE_FORMAT)

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return result
}

export const getQuotaTzDate = (date, time) => {
  let briefDate = moment(moment.utc(date), DATE_FORMAT)
    .tz(moment.tz.guess())
    .format(DATE_FORMAT)

  return getUnixFromTzDate(briefDate, time)
}

export const getDateUpdatedFromLocalTz = date =>
  moment(moment.utc(date), DATE_FORMAT)
    .tz(moment.tz.guess())
    .format(DATE_FORMAT)

export const pickerTzDate = (date, isFinish = false) => {
  if (!date) {
    return null
  }
  // вначале по таймзоне браузера забираем дату
  moment.tz.setDefault()
  let localDate = moment(date).format(DATE_FORMAT)

  // затем по времени терминала переводим ее в начало дня
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])
  let defaultDate = moment(localDate, DATE_FORMAT).format('X')

  // и конвертим в МСК для передачи на бэк (если это конец периода - добавляем день)
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
  let terminalDate = isFinish
    ? moment(defaultDate, 'X').add(1, 'days')
    : moment(defaultDate, 'X')

  return terminalDate.format(DATE_FORMAT_FULL)
}

// срок окончания нахождения машины в черном списке по требованию бэка
// отдаём переведенным в мск
export const getBlacklistTerm = date => {
  let textDate = moment(date).format(DATE_FORMAT)

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  let unixDate = moment(textDate, DATE_FORMAT).endOf('day').format('X')

  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])

  return moment(unixDate, 'X').format(DATE_FORMAT_FULL)
}

export const blacklistDateBrief = date => moment(date).format(DATE_FORMAT_RU)

export const dateTzInTwoWeeks = () => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return moment().add(2, 'weeks').startOf('day').format(DATE_FORMAT_FULL)
}

export const terminalTzDate = () => nowTz().format('DD MMMM HH:mm')

// методы для страницы Начальник смены (Supervisor)

export const diffSupervisorDates = (dateFrom, timeFrom, dateTo, timeTo) => {
  let start = Number(
    moment(`${dateFrom} ${timeFrom}`, DATE_FORMAT_HOURS).format('x'),
  )
  let finish = Number(
    moment(`${dateTo} ${timeTo}`, DATE_FORMAT_HOURS).format('x'),
  )

  return finish - start
}

export const addIntervalTimeTz = (dateFrom, timeFrom, interval) => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])
  let newTime = moment(`${dateFrom} ${timeFrom}`, DATE_FORMAT_HOURS).add(
    interval,
    'ms',
  )

  return newTime.format(GET_TIME)
}

export const addIntervalDateTz = (dateFrom, timeFrom, interval) => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])
  let newTime = moment(`${dateFrom} ${timeFrom}`, DATE_FORMAT_HOURS).add(
    interval,
    'ms',
  )

  return newTime.format(DATE_FORMAT)
}

export const addHourTz = data => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return `${getTzTime(moment(data).add(1, 'h'), GET_TIME)}`
}

export const getDefaultDateUnix = date => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])

  let defaultDateUnix = moment(date, DATE_FORMAT_FULL).format('x')

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return Number(defaultDateUnix)
}
export const convertQuotaDateToTz = date => {
  let defaultDateUnix = getDefaultDateUnix(date)

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return moment(defaultDateUnix, 'x').format(DATE_FORMAT_FULL)
}

export const supervisorDate = (date, format) => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
  let dateTz = moment(date).format('X')

  moment.tz.setDefault(textTimezones[localStorage.getItem('terminal-timezone')])

  return moment(dateTz, 'X').format(format)
}

export const startOfTzToday = () => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])
  let dateTz = moment().format(DATE_FORMAT)

  moment.tz.setDefault()
  let localDate = moment(dateTz, DATE_FORMAT)

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return Number(localDate.format('x'))
}

export const startArchivePickerOptions = {
  disabledDate(time) {
    moment.tz.setDefault()
    let terminalDate = moment(time).format(DATE_FORMAT)

    moment.tz.setDefault(
      textTimezones[localStorage.getItem('terminal-timezone')],
    )

    return (
      getDateToUnix(terminalDate) < nowUnix() - MILLISECOND_IN_DAY * 366 ||
      getDateToUnix(terminalDate) > nowUnix()
    )
  },
}

export const startArchiveDates = () => {
  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])
  let today = moment().format(DATE_FORMAT)

  moment.tz.setDefault()

  return {
    to: moment(today, DATE_FORMAT).format('x'),
    from: moment(today, DATE_FORMAT).subtract(6, 'days').format('x'),
  }
}

export const startDateToTz = date => {
  moment.tz.setDefault()
  let day = moment(date, 'x').format(DATE_FORMAT)

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return moment(day, DATE_FORMAT).format('X')
}

export const finishDateToTz = date => {
  moment.tz.setDefault()
  let day = moment(date, 'x').format(DATE_FORMAT)

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])
  let dayTz = moment().format(DATE_FORMAT)

  return day === dayTz
    ? moment().format('X')
    : moment(day, DATE_FORMAT).add(1, 'days').format('X')
}

export const archiveDateToOptions = date => {
  moment.tz.setDefault()
  let day = moment(date, 'x').format(DATE_FORMAT)

  moment.tz.setDefault(textTimezones[store.getters[GET_TIMEZONE_OFFSET]])

  return moment(day, DATE_FORMAT).format('x')
}
