export const toBD = plate => {
  plate = plate.toUpperCase()
  plate.replace('А', 'A')
  plate.replace('В', 'B')
  plate.replace('Е', 'E')
  plate.replace('К', 'K')
  plate.replace('М', 'M')
  plate.replace('Н', 'H')
  plate.replace('О', 'O')
  plate.replace('Р', 'P')
  plate.replace('С', 'C')
  plate.replace('Т', 'T')
  plate.replace('У', 'Y')
  plate.replace('Х', 'X')

  return plate
}

export const fromBD = plate => {
  plate = plate.toLowerCase()
  plate.replace('a', 'а')
  plate.replace('b', 'в')
  plate.replace('e', 'е')
  plate.replace('k', 'к')
  plate.replace('m', 'м')
  plate.replace('h', 'н')
  plate.replace('o', 'о')
  plate.replace('p', 'р')
  plate.replace('c', 'с')
  plate.replace('t', 'т')
  plate.replace('y', 'у')
  plate.replace('x', 'х')

  return plate
}
