export const GET_EXPORTERS_FOR_TABLE =
  '[Control/getter] GET_EXPORTERS_FOR_TABLE'
export const GET_EXPORTERS_ALL = '[Control/getter] GET_EXPORTERS_ALL'
export const GET_EXPORTERS_PAGINATION =
  '[Control/getter] GET_EXPORTERS_PAGINATION'
export const GET_IS_LOADING_EXPORTERS =
  '[Control/getter] GET_IS_LOADING_EXPORTERS'

export const GET_CULTURE_FROM_STATE = '[Control/getter] GET_CULTURE_FROM_STATE'
export const GET_CULTURE_FOR_TABLE = '[Control/getter] GET_CULTURE_FOR_TABLE'
export const GET_CULTURE_PAGINATION = '[Control/getter] GET_CULTURE_PAGINATION'
export const GET_PREPARE_CULTURE_FROM_STATE =
  '[Control/getter] GET_PREPARE_CULTURE_FROM_STATE'
export const GET_IS_LOADING_CULTURE = '[Control/getter] GET_IS_LOADING_CULTURE'

export const FETCH_EXPORTERS_PAGE = '[Control/action] FETCH_EXPORTERS_PAGE'
export const FETCH_EXPORTERS_ALL = '[Control/action] FETCH_EXPORTERS_ALL'
export const DELETE_EXPORTERS = '[Control/action] DELETE_EXPORTERS'
export const SAVE_EXPORTER = '[Control/action] SAVE_EXPORTER'

export const FETCH_CULTURES = '[Control/action] FETCH_CULTURES'
export const FETCH_CULTURES_PAGE = '[Control/action] FETCH_CULTURES_PAGE'
export const POST_CULTURE = '[Control/action] POST_CULTURE'
export const DELETE_CULTURE = '[Control/action] DELETE_CULTURE'
export const PUT_CULTURE = '[Control/action] PUT_CULTURE'

export const SET_EXPORTERS_PAGE = '[Control/mutation] SET_EXPORTERS_PAGE'
export const SET_EXPORTERS_ALL = '[Control/mutation] SET_EXPORTERS_ALL'
export const SET_IS_LOADING_EXPORTERS =
  '[Control/mutation] SET_IS_LOADING_EXPORTERS'

export const SET_IS_LOADING_CULTURE =
  '[Control/mutation] SET_IS_LOADING_CULTURE'
export const SET_CULTURES = '[Control/mutation] SET_CULTURES'
export const SET_CULTURES_PAGE = '[Control/mutation] SET_CULTURES_PAGE'
export const SET_NEW_CULTURES = '[Control/mutation] SET_NEW_CULTURES'
export const SET_PUT_CULTURES = '[Control/mutation] SET_PUT_CULTURES'

export const GET_BLACKLIST_PAGE = '[Control/getter] GET_BLACKLIST_PAGE'
export const GET_BLACKLIST_PAGINATION =
  '[Control/getter] GET_BLACKLIST_PAGINATION'
export const GET_IS_LOADING_BLACKLIST =
  '[Control/getter] GET_IS_LOADING_BLACKLIST'
export const FETCH_BLACKLIST_PAGE = '[Control/action] FETCH_BLACKLIST_PAGEE'
export const SET_BLACKLIST_PAGE = '[Control/mutation] SET_BLACKLIST_PAGE'
export const SET_IS_LOADING_BLACKLIST =
  '[Control/mutation] SET_IS_LOADING_BLACKLIST'
export const DELETE_BLACKLIST = '[Control/action] DELETE_BLACKLIST'

export const SAVE_BLACKLIST = '[Control/action] SAVE_BLACKLIST'
