export const auth = {
  auth: '/auth',
  login: '/login',
  sms: '/sms-code',
  register: '/register',
  reset: '/reset-password',
}

export const control = {
  cultures: '/cultures/index',
  culturesActiveQuotasList: '/cultures/current-terminal-state',
  culturesPublic: '/public/cultures-list',
  culturesCreate: '/cultures/create',
  culturesUpdate: '/cultures/update',
  culturesDelete: '/cultures/delete',

  suppliersPage: '/pages/suppliers-and-phones',
  suppliersCheck: '/suppliers/check-inn',
  suppliersCreate: '/suppliers/create',
  suppliersUpdate: '/suppliers/update',
  suppliersDelete: '/suppliers/delete',

  terminalQuotas: '/quotas/get-parent-quotas',

  autos: '/user-trucks/index',
  suppliersAddPhones: '/suppliers-phones/create',
  suppliersUpdatePhones: '/suppliers-phones/update',
  suppliersDeletePhones: '/suppliers-phones/delete',

  checkUserByPhone: '/users/check-by-phone',

  exporters: '/exporters/index',
  exportersCreate: '/exporters/create',
  exportersUpdate: '/exporters/update',
  exportersDelete: '/exporters/delete',
  userExporters: '/exporters/get-user-exporters',

  blacklist: '/v2/blacklist/list-auto',
  blacklistCreate: '/v2/blacklist/add-auto',
  blacklistUpdate: '/v2/blacklist/update-auto ',
  blacklistDelete: '/v2/blacklist/delete-auto',
}

export const terminal = {
  terminals: '/stevedore-unloads/index',
  terminalQueuePage: '/pages/unload-queue-stats',
  terminalQueuePosition: '/timeslots/position-in-queue',
  terminalPing: '/users/ping',
}

export const quota = {
  list: '/pages/quotas-statistics',
  parkingCulturesStat: '/pages/cultures-parking-stats',
  addQuota: '/quotas/create',
  deleteQuota: '/quotas/delete',

  // обновление квоты экспортера
  updateExporterQuota: '/exporters/quota-change',

  // квоты поставщика
  saveSupplierQuota: '/exporters/quota-input',
  deleteSupplierQuota: '/exporters/quota-delete',
}

// шаблоны квот
export const quotaSamples = {
  // шаблон квоты экспортера
  list: '/template-quotas/index',
  addSample: '/template-quotas/create',
  updateSample: '/template-quotas/exporter-quota-change',

  // создание/обновление шаблона квоты поставщика
  saveSupplierSample: '/template-quotas/exporter-quota-input',

  // удаление шаблона квоты (общий и для поставщика, и для экспортера)
  deleteSample: '/template-quotas/remove',

  // применение шаблона квот
  applySamples: '/template-quotas/apply',
}

export const supervisor = {
  supervisorStatisticTable: '/pages/supervisor-linear-statistics',
  getSupervisorQuotas: '/buffer-planed-quotas/index',
  postSupervisorQuotas: '/buffer-planed-quotas/create',
  putSupervisorQuotas: '/buffer-planed-quotas/mass-input',
  deleteSupervisorQuotas: '/buffer-planed-quotas/delete',
  getSupervisorQuotasLines: '/stevedore-unload-lines/index',
  postSupervisorQuotasLine: '/stevedore-unload-lines/create',
  deleteSupervisorQuotasLine: '/stevedore-unload-lines/delete',
  // TODO поменять адрес на реальный
  getSupervisorArchive: '/buffer-planed-quotas/index',
}

export const supervisorSamples = {
  samplesList: '/template-buffer-rules/list',
  postSample: '/template-buffer-rules/input',
  deleteSample: '/template-buffer-rules/delete',
  applySamples: '/template-buffer-rules/apply',
}

export const drivers = {
  createTimeRequest: '/timeslot-requests/create-time-request',
  timeslotData: '/timeslot-requests/my-with-timeslots',
  cultures: '/cultures/index',
  terminals: '/stevedore-unloads/index',
  suppliers: '/suppliers/index',
  timeslotsSubmit: '/timeslots/submit',
  timeslotMoveRequest: '/timeslot-requests/create-move-request',
  removeTimeslot: '/timeslots/drop',
  getTimeslots: '/pages/my-timeslots',
  getAutocomplete: '/timeslot-request-autofills/index',
  getCommonSupplierAutoComplete: '/common-suppliers/autofill',
  createAutocomplete: '/timeslot-request-autofills/create',
  getUserProfile: '/domain/user-profile/get-user-profile',
  commonSuppliersCheckInn: '/common-suppliers/check-inn',
  publicCheckInn: '/public/check-inn',
}

export const timeslots = {
  timeslots: 'pages/timeslots-with-statistic',
  timeslotsHasDeleted: 'pages/timeslots-with-deleted-list',
  buffer: 'pages/buffer-timeslots',
  statusArrived: '/timeslots/status-arrived',
  timeslotCreate: '/timeslots/create',
  timeslotPush: '/timeslots/push',
  timeslotDelete: '/timeslots/force-delete',
  timeslotUpdate: '/timeslots/force-update',
  currentTimeslot: 'timeslots/check',
  timeslotStatusParkedFar: 'timeslots/status-parked-far',
  timeslotFromBufferToParking: '/timeslots/detach',
  getLogsByTimeslot: '/notifications/index',
  detachTimeslot: '/timeslots/detach',
  handTimeslotRest: '/timeslots/manual-limit-info',
}

export const profile = {
  getUsers: '/users/index',
  updateProfile: '/users/update-profile',
  createUser: '/users/create',
  updateUser: '/users/update',
  deleteUser: '/users/delete',
  getUserData: 'user-profile/get-user-profile',
}

export const publicStatic = {
  publicStatic: 'public/hour-limit',
  publicUnloadst: '/public/stevedore-unloads',
}
export const settings = {
  getEditorText: '/trash/unload-text-get',
  getUnloadInfo: '/unload-settings/get',
  setEditorText: '/trash/unload-text-set',
  setNewSettings: '/unload-settings/set',
}

export const tablet = {
  getAuth: '/domain/geo-tokens/get-auth',
  postAuth: '/domain/geo-tokens/create-auth',
  checkQrToken: '/domain/geo-tokens/create-arrival-confirm',
}

export const mobile = {
  checkGeoCode: '/domain/driver-self-arrival/get-permission',
  checkConfirmedPhone:
    '/domain/driver-self-arrival/check-driver-phone-confirmed',
  assignDriverPhone:
    '/domain/driver-self-arrival/assign-driver-phone-confirmed',
  confirmPhoneByPermission:
    '/domain/driver-self-arrival/request-phone-confirm-by-permission',
  submitPhoneByPermission:
    '/domain/driver-self-arrival/submit-phone-confirm-by-permission',
  timeslotInfo: '/driver-self-arrival/timeslot-info',
  parkingTimeslot: '/driver-self-arrival/proceed-to-parking',
  createHandTimeslot: '/driver-self-arrival/create-manual-timeslot',
  pushTimeslot: '/driver-self-arrival/push-timeslot',
}

export const reports = {
  managerExcelReport: '/trash/timeslots-xlsx',
}
